@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Public Sans", sans-serif;
  overflow-x: hidden;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 7px;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.page-exit {
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 20px;
}

::-webkit-scrollbar-track:hover {
  background: #f4f6f8;
}
